.insights{
    background-image: url("../../../public/images/Cloud-migration-Blog-Banner-.jpg");
    background-position: center;
    display: flex;
    height: auto;
    

    .insightsContainer{
        width: 100%;

        .background{
            display: flex;
            background-color: black;
            height: 60vh;
            opacity: 0.60;
        }

        .heading-title{
            position: absolute;
            background-color: rgba(255, 255, 255, 0);
            height: auto;
            width: auto;
            padding: 10px;
            text-align: left;
            left: 10vw;
            top: 30vh;
            .title{
                color: #fff;   
            }
        }

        .services{
            display: flex;
            background-color: #fff;
            height: auto; 
            justify-content: space-evenly;
            width: 100%;
            flex-wrap: wrap;
            padding: 50px;

            .service{
                height: 50vh;
                width: 25%;
                padding: 20px;
                margin: 20px;
                background-color: aqua;
            }
        }        
    }
}