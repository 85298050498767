.controlTower{
    display: flex;
    height: auto;
    

    .controlTowerContainer{
        width: 100%;

        .background{
            display: flex;
            height: 70vh;
        }

        .heading-title{
            position: absolute;
            background-color: rgba(255, 255, 255, 0.1);
            height: auto;
            width: auto;
            padding: 10px;
            text-align: left;
            left: 9.5vw;
            top: 30vh;
            .title{
                color: #fff;   
            }
        }

        .services{
            background-color: #fff;
            height: auto; 
            justify-content: space-evenly;
            width: 100%;
            flex-wrap: wrap;
            padding: 50px;

            .heading{
                color: #000;
                padding-left: 6vw;
            }

            .header{
                height: auto;
                width: 87%;
                padding: auto;
                margin: auto;
                justify-content: center;

                h1{
                    color: #232443;
                }

                img{
                    height: auto;
                    width: auto;
                    padding: 10px;
                }
            }
        }        
    }
}