.story{
    display: flex;
    height: auto;
    

    .storyContainer{
        width: 100%;

        .background{
            display: flex;
            background-color: black;
            height: 60vh;
        }

        .heading-title{
            position: absolute;
            background-color: rgba(0, 0, 0, 0.6);
            height: auto;
            width: auto;
            padding: 10px;
            text-align: left;
            left: 8vw;
            top: 30vh;
            .title{
                color: #fff;   

                p{
                    width: 50vw;
                }
            }
        }

        .services{
            background-color: #fff;
            height: auto; 
            justify-content: center;
            width: 100%;
            flex-wrap: wrap;
            padding: 50px;
            display: flex;

            .about-us{
                width: 90%;
                
                p{
                    font-size: 20px;
                    text-align: justify;
                }
            }

            .heading{
                width: 91.5%;
                margin: 6vh 0px 0px 0px;
                h1{
                    text-align: left;
                    margin: 10px;
                    font-size: 40px;
                }
                p{
                    text-align: left;
                    margin: 10px;
                    font-size: 18px;
                    color: #3f3f42;
                }
            }

            .values{
                display: flex;
                width: 91.5%;
                justify-content: center;
                margin: 20px;


                .block{
                    height: 20vh;
                    width: 25%;
                    color: #fff;
                    justify-content: center;
                    padding: 10px;
                    margin: 10px;
                    border-radius: 20px;
                    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
                    height: auto;

                    h2{
                        font-weight: 500;
                        padding: 5px;
                        color: #232443;
                    }
                    p{
                        padding: 5px;
                        color: #3f3f42;
                        font-size: 20px;
                    }
                }
                
            }

            .our-culture{
                display: flex;
                width: 100%;
                justify-content: center;
                margin: 20px;

                .block{
                    height: auto;
                    width: 30%;
                    color: #fff;
                    justify-content: center;

                    img{
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                        width: 50%;
                    }
                    h2{
                        text-align: center;
                        font-weight: lighter;
                        color: #232443;
                        padding: 20px;
                    }
                }
                
            }

            .statement{
                width: 90%;

                h2{
                    font-weight: 400;
                    font-size: 25px;
                    padding: 15px 0px 0px 0px;
                }

                p{
                    font-size: 18px;
                }
            }

            .partner{
                height: auto;
                width: 90%;
                padding: 10px;
                margin: 10px;
                display: flex;

                .img{
                    flex: 2;
                    width: 100%;
                    padding: 10px;
                }

                .para1{
                    flex: 3;
                    width: 100%;
                    padding: 10px;

                    p{
                        text-align: justify;
                    }
                }
            }

            .promise{
                height: auto;
                width: 90%;
                padding: 10px;
                margin: 10px;
                display: flex;

                .img{
                    flex: 1;
                    width: 100%;
                    padding: 10px;

                    h1{
                        font-weight: lighter;
                    }
                }

                .para1{
                    flex: 3;
                    width: 100%;
                    padding: 10px;

                    p{
                        text-align: justify;
                    }
                }
            }

            .success{
                display: flex;
                width: 90%;
                justify-content: center;

                .block{
                    height: 20vh;
                    width: 23%;
                    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
                    color: #232443;
                    justify-content: center;
                    padding: 20px;
                    margin: 10px;
                }
                
            }

        }        
    }
}