.unified{
    background-position: center;
    display: flex;
    height: auto;
    

    .unifiedContainer{
        width: 100%;

        

        .background{
            display: flex;
            background-color: black;
            height: 70vh;


        }

        .heading-title{
            position: absolute;
            background-color: rgba(255, 255, 255, 0.2);
            height: auto;
            width: auto;
            padding: 10px;
            text-align: left;
            left: 7.5vw;
            top: 30vh;
            .title{
                color: #fff;   
            }
        }

        .content-element{
            height: fit-content;
            margin: auto;
            width: 87%;

            .header{
               height: 100%; 

                .services-header{
                    h3{
                        color: #232443;
                        font-size: 30px;
                        padding: 15px;
                    }
    
                    p{
                        color: #000;
                        font-size: 20px;
                        text-align: justify;
                        padding: 15px;
                    }
                }
            }

        }

        .head{
            h1{
                text-align: center;
                padding: 10px;
            }
            p{
                text-align: center;
            }
        }

        .services{
            display: flex;
            background-color: #fff;
            height: auto; 
            justify-content: center;
            width: 87%;
            flex-wrap: wrap;
            margin: auto;

            .service{
                height: auto;
                width: 25%;
                padding: 20px;
                text-align: left;
                display: flex;

                img{
                    width: auto;
                    height: 6vh;
                    margin: 10px;
                }

            }

        } 
        
        .get-started{
            display: flex;
            height: auto; 
            justify-content: center;
            width: 92%;
            margin: auto;
            padding: 50px;

            .left{
                width: 100%;
                
                h1{
                    margin: 10px 0px;
                }

                h2{
                    margin: 15px 0px 3px 0px;
                }
                
            }

            .right{
                width: 100%;
                padding: 10vh 20px;
                

                img{
                    width: 100%;
                    height: auto;
                }
            }

        } 

        .download{
            padding: 5vh 5vw;
            font-size: 25px;

            a{
            background-color: #232443;
            width: auto;
            height: auto;
            padding: 10px;
            border-radius: 10px;
            text-decoration: none;
            color: #bebec3;
            }

            a:hover{
                background-color: #bebec3;
                color: #232443;
                transition: .5s ease;
            }
            
        }
    }
}