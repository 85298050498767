.home{
    background-position: center;
    display: flex;
    height: auto;
    

    .homeContainer{
        width: 100%;

        .heading{
            h1{
                padding-top: 6vh;
                padding-left: 9vw;
                font-size: 40px;
            }
        }

        .background{
            display: flex;
            background-color: black;
            height: 90vh;
            width: 100%;
        }

        .heading-title{
            position: absolute;
            background-color: rgba(0, 0, 0, 0.0);
            height: auto;
            width: auto;
            padding: 10px;
            text-align: left;
            left: 7vw;
            top: 30vh;
            .title{
                color: #fff;   

                p{
                    background-color: rgba(255, 255, 255, 0.2);
                    padding: 10px;
                    width: 40vw;
                }

                h1{
                    padding: 10px;
                }

                .message{
                    background-color: transparent;
                    text-align: justify;
                    padding: 10px;
                    width: 50vw;
                }
            }
        }

        .services{
            display: flex;
            background-color: #fff;
            height: auto; 
            width: 100%;
            justify-content: left;
            flex-wrap: wrap;
            padding-left: 7.5vw;


            Button{
                width: 31%;
                color: #000;
                position: relative;
                padding: 20px;
            }

            .service{
                height: 50vh;
                width: 100%;
                padding: auto;
                margin: auto;
                position: relative;
                cursor: pointer;

                .overlay{
                    position: absolute;
                    bottom: 15vh;
                    left: 0;
                    right: 0;
                    background-color: #000;
                    overflow: hidden;
                    width: 100%;
                    height: 0;
                    transition: .5s ease;
                    opacity: 0.9;
                }
            }

            .service:hover .overlay{
                cursor: pointer;
                height: 70%; 
                color: #fff;           
            }

        }
        
        .service-header{
            display: flex;
            background-color: #fff;
            height: auto; 
            justify-content: center;
            width: 100%;
            flex-wrap: wrap;
            text-align: justify;

            .service1{
                height: auto;
                width: 42%;
                padding: 20px;
                margin: 10px;
                position: relative;
                cursor: pointer;

                h1{
                    font-size: 40px;
                    text-align: left;
                }
            }
        }
    }
}