.management{
    background-position: center;
    display: flex;
    height: auto;
    

    .managementContainer{
        width: 100%;

        .background{
            display: flex;
            background-color: black;
            height: 50vh;
        }

        .heading-title{
            position: absolute;
            background-color: rgba(0, 0, 0, 0.5);
            height: auto;
            width: auto;
            padding: 10px;
            text-align: left;
            left: 10vw;
            top: 30vh;
            .title{
                color: #fff;   
            }
        }

        .services{
            display: flex;
            background-color: #fff;
            height: auto; 
            justify-content: space-evenly;
            width: 100%;
            flex-wrap: wrap;
            padding: 10px;

            Button{
                width: 30%;
                padding: 20px;
                margin: 20px;
                color: #000;
                position: relative;
            }

            .service{
                height: 50vh;
                width: 100%;
                padding: 20px;
                margin: 20px;
                background-color: #232443;
                color: #bebec3;
                position: relative;
                cursor: pointer;

                .overlay{
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background-color: #bebec3;
                    color: #232443;
                    overflow: hidden;
                    width: 100%;
                    height: 0;
                    transition: .5s ease;
                }
            }

            .service:hover .overlay{
                cursor: pointer;
                height: 100%;
                
            }

        }        
    }
}