.crm{
    background-position: center;
    display: flex;
    height: auto;
    

    .crmContainer{
        width: 100%;

        h2{
            padding: 30px;
        }

        .background{
            display: flex;
            background-color: black;
            height: 70vh;
        }

        .heading-title{
            position: absolute;
            background-color: rgba(255, 255, 255, 0.2);
            height: auto;
            width: 30vw;
            padding: 10px;
            text-align: left;
            left: 7.5vw;
            top: 30vh;
            .title{
                color: #fff;   
            }
        }

        .content-element{
            height: fit-content;
            margin: auto;
            width: 87%;

            .header{
               height: 100%; 

                .services-header{
                    h3{
                        color: #232443;
                        font-size: 30px;
                        padding: 15px;
                    }
    
                    p{
                        color: #000;
                        font-size: 20px;
                        text-align: justify;
                        padding: 15px;
                    }
                }
            }

        }

        .head{
            h1{
                text-align: center;
                padding: 10px;
            }

            p{
                text-align: center;
            }
        }
        .services{
            display: flex;
            height: auto; 
            justify-content: center;
            width: 100%;
            flex-wrap: wrap;
            padding: 20px;
            text-align: justify;

            .service{
                height: auto;
                width: 28%;
                padding: 30px;
                margin: 10px;
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.05);
                position: relative;
                cursor: pointer;  
            }
        } 
        
        .tabContainer{
            display: flex;
            height: auto;
            padding: 60px 60px;
            background-color: #ededf3;
            margin: auto;
            width: 85%;
            

            .content{
                height: 100%;
                margin: 0;
                width: 100%;

                .tablink {
                    height: auto;
                    color: #232443;
                    float: left;
                    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.05);
                    border: none;
                    cursor: pointer;
                    padding: 14px 16px;
                    font-size: 17px;
                    width: 25%;
                  }
                  .tablink:hover{
                    background-color: #232443;
                    color: #ededf3;
                    transition: 0.5s;
                }


                  .tabcontent {
                    display: none;
                    padding: 50px 20px;
                    height: auto;

                    .left{
                        flex: 1;
                        width: auto;
                        height: auto;
                        

                        img{
                            display: flex;
                            margin-left: auto;
                            margin-right: auto;
                            width:80%;
                            height: auto;
                        }
                        
                    }

                    .right{
                        flex: 1;
                        width: auto;
                        height: auto;
                        

                        h1{
                            font-size: 45px;
                            color: #232443;
                        }
                        p{
                            font-size: 25px;
                            color: #232443;
                        }

                        a{
                            font-size: 18px;
                            padding: 100px 0px;
                        }
                    }
                  }
            }
        }
    }
}