.samsung{
    background-position: center;
    display: flex;
    height: auto;
    

    .samsungContainer{
        width: 100%;

        .background{
            display: flex;
            height: 70vh;
        }

        .heading-title{
            position: absolute;
            background-color: rgba(255, 255, 255, 0.2);
            height: auto;
            width: 50%;
            padding: 10px;
            text-align: left;
            left: 7.5vw;
            top: 30vh;
            .title{
                color: #fff;   
            }
        }

        .content-element{
            height: fit-content;
            margin: auto;
            width: 87%;

            .header{
               height: 100%; 

                .services-header{
                    h3{
                        color: #232443;
                        font-size: 30px;
                        padding: 15px;
                    }
    
                    p{
                        color: #000;
                        font-size: 20px;
                        text-align: justify;
                        padding: 15px;
                    }
                }
            }

        }

        .head{
            h1{
                text-align: center;
                padding: 10px;
            }
            p{
                text-align: center;
            }
        }
        .services{
            display: flex;
            background-color: #fff;
            height: auto; 
            justify-content: center;
            width: 100%;
            flex-wrap: wrap;
            padding: 10px;
            text-align: justify;

            .service{
                height: auto;
                width: 42.5%;
                padding: 30px;
                margin: 10px;
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
                position: relative;
                cursor: pointer;

                ul{
                    li{
                        list-style:disc;
                        padding: 5px 0px;
                    }
                }

                h2{
                    text-align: left;
                }
            }

        }        
    }
}