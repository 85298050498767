.footer{
    background-color: #232443;
    height: auto;
    position: relative;
    color: #bebec3;

    .footerContainer{
        display: flex;
        width: 100%;

        .left-mid{
            flex: 2;
            background-color: #232443;
            height: auto;
            padding: 50px;
        }

        .mid{
            flex: 1;
            background-color: #232443;
            height: auto;
            padding: 50px;

            ul{
                list-style: none;
            }
            
        }

        .right{
            flex: 1;
            background-color: #232443;
            height: auto;
            padding: 50px;

            ul{
                list-style: none;

                li{
                    a{
                        color: #bebec3;
                        text-decoration: none;
                    }
                }
            }
            
        }

    }
    .bottomFooter{
        display: flex;
        padding: 20px;
        border-top-style: solid;

        .copyright{
            display: flex;
            width: 90vw;
            position: relative;
            left: 5vw;
        }

        .icons{
            position: relative;
            display: flex;
            width: 10vw;
            right: 5vw;
            justify-content: space-evenly;
            cursor: pointer;

            a{
                color: #bebec3;
            }
        }
    }
    
}