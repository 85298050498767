.testimonial{
    background-position: center;
    display: flex;
    height: auto;
    

    .testimonialContainer{
        width: 100%;

        .background{
            display: flex;
            height: 70vh;
        }

        .heading-title{
            position: absolute;
            background-color: rgba(255, 255, 255, 0.2);
            height: auto;
            width: auto;
            padding: 10px;
            text-align: left;
            left: 7.5vw;
            top: 30vh;
            .title{
                color: #fff;   
            }
        }

        .services{
            display: flex;
            background-color: #fff;
            height: auto; 
            justify-content: center;
            width: 100%;
            flex-wrap: wrap;
            padding: 10px;
            text-align: justify;

            .service{
                height: auto;
                width: 42.5%;
                padding: 30px;
                margin: 10px;
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.05);
                position: relative;
                cursor: pointer;

                img{
                    width: 30%;
                    height: auto;
                }

            }

        }        
    }
}